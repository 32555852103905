import styled from "styled-components";

export const Sheet = styled.div`
  padding: 5px;
  font-family: "Times New Roman", Times, serif;
  width: 210mm;
  height: 297mm;

  @media print {
    width: 210mm;
    height: 297mm;

    page-break-after: always;
  }
`;

export const SheetWrapper = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  padding: 10px;
  min-width: 720px;

  z-index: 1;
  border: 3px solid #000;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
// Header ===================================== //
export const Header = styled.div`
  width: 100%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;

  width: 100px;
  height: 100px;

  transform: translateY(-50%);

  img {
    width: 100%;
    height: 100%;

    display: block;
    object-fit: cover;
  }
`;

export const Watermark = styled.img`
  display: block;
  width: 380px;
  height: 380px;

  object-fit: cover;
  object-position: center;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  z-index: -1;
  opacity: 0.2;
`;

export const ContactInfo = styled.div`
  padding-top: 10px;
  padding-right: 30px;
  & > span {
    font-size: 12px;
    font-weight: 300;

    font-style: italic;
  }
`;

export const ExamTitle = styled.div`
  border: 1px solid #000;
  border-top: none;
  border-bottom: none;
  text-align: center;

  padding: 5px;
  font-size: 18px;
  font-weight: 400;
`;

export const SchoolInfoDiv = styled.div`
  margin-top: 5px;
  flex: 1;
  text-align: center;

  h1,
  h2,
  h3,
  p {
    text-align: center;
    text-transform: uppercase;
  }
`;

export const SloganText = styled.h3`
  font-size: 16px;
  font-weight: 600;

  margin: 0 auto;
  line-height: 1;
`;
export const SchoolNameText = styled.h2`
  font-size: 22px;
  font-weight: 600;
  margin: 0 auto;
  line-height: 1;
  margin-bottom: 2px;
`;
export const SchoolAddressText = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-transform: unset;
  margin: 0 auto;
`;
export const ExamNameText = styled.h3`
  font-size: 18px;
  font-weight: 600;

  margin: 0 auto;
  line-height: 1;
`;
export const GradeSheetText = styled.h1`
  font-size: 22px;
  font-weight: 700;
  display: inline-block;

  border: 5px solid #000;
  padding: 2px 14px;

  margin-bottom: 0;
  text-transform: uppercase;
`;
export const ClassNameText = styled.h3`
  font-size: 16px;
  font-weight: 600;

  margin: 0 auto;
  margin-top: 5px;

  & > span {
    text-transform: uppercase;
  }
`;

// ============================================ //

// Student Info =============================== //
export const StudentInfoWrapper = styled.div`
  width: 100%;
  padding: 0 30px;
`;

export const StudentInfo = styled.div`
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  border: 2px solid #000;
  padding: 5px 10px;

  & > p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`;

export const KeyValue = styled.div`
  display: flex;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  & > span {
    display: table-cell;
    &:last-child {
      position: relative;
      font-weight: 500;

      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        min-width: 180px;
        max-width: 100%;
        border-bottom: 1px solid #000;
      }
    }
  }
`;
// ============================================ //

// Main ======================================= //
export const Main = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  margin-top: 10px;
  table {
    width: 100%;
    border-collapse: collapse;
    tbody {
      border: 2px solid #000;
    }
    & > * {
      & > tr {
        /* &:first-child {
          & > td {
            padding-top: 5px;
          }
        } */

        & > th,
        td {
          font-size: 14px;
          border: 2px solid #000;
        }

        & > th {
          padding: 3px 5px;
          border: 2px solid #000;
          font-weight: 500;
        }

        & > td {
          vertical-align: top;
          padding: 2px 5px;
          border-left: 2px solid #000;
          border-right: 2px solid #000;
          border-top: none;
          font-weight: 500;

          & > p {
            margin: 0;
          }
        }
      }
    }
  }
`;

export const EmptyRow = styled.tr`
  & > td {
    padding-top: 60px !important;
    /* border: none !important; */

    :first-child {
      border-left: 2px solid #000 !important;
    }

    :last-child {
      border-right: 2px solid #000 !important;
    }
  }
`;

export const BorderedTableData = styled.td<{ align?: string }>`
  border: 2px solid #000 !important;
  text-align: ${(props) => props.align};
`;

export const TableFooter = styled.div`
  width: 100%;
  border-bottom: 2px solid #000;
`;
// ============================================ //

// Footer ===================================== //
export const Footer = styled.div`
  padding: 5px 15px 5px 15px;

  table {
    border-collapse: collapse;
    border: 2px solid #000;

    & > * {
      & > tr {
        & > th,
        td {
          font-size: 13px;
        }

        & > th {
          padding: 2px 5px;
          /* padding: 2px 5px; */
          font-weight: 500;
          border-bottom: 2px solid #000;

          &:not(:last-child) {
            border-right: 2px solid #000;
          }
        }

        & > td {
          padding: 0 5px;
          font-weight: 500;

          &:not(:last-child) {
            border-right: 2px solid #000;
          }
        }
      }
    }
  }
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > div {
    text-align: center;
    margin: 0 auto;

    & > p {
      font-size: 16px;
      font-weight: 600;

      margin: 0 auto;
      margin-bottom: 5px;
    }

    & > h3 {
      margin-bottom: 5px;
    }
  }
`;

export const SchoolSeal = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  border: 1px solid #000;
  padding-bottom: 30px;

  & > span {
    display: inline-block;

    &::after {
      content: "";
      display: inline-block;
      width: 200%;
      height: 1px;
      background-color: #000;
      margin-bottom: 10px;

      transform: translateX(-20%);
    }
  }
`;

export const FooterBottom = styled.div`
  width: 100%;

  margin-top: 30px;

  display: flex;
  align-items: flex-end;
  justify-content: space-around;

  & > span {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;

    &:nth-child(2),
    :nth-child(3) {
      &::before {
        content: "";
        display: block;
        width: calc(100% + 40px);
        border-top: 2px dotted #000;

        position: absolute;
        top: -6px;
        left: 0;

        transform: translateX(calc(-20px));
      }
    }
  }
`;

export const DaysSection = styled.div`
  display: flex;
  justify-content: space-around;

  border: 1px solid #000;
  padding-bottom: 25px;
  margin-bottom: 10px;

  & > span {
    font-weight: 500;
  }
`;

export const DateSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;

  & > p {
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    white-space: nowrap;

    transform: translateX(-50%);
  }
`;

export const FooterDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    font-size: 12px;
    font-weight: 500;
    &:first-child {
      text-decoration: underline;
    }
  }
`;
//  =========================================== //
