// ---------------------------- module imports start ----------------------------------//

import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "../Styles/FormStyles";
import { RootStore } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  GetGeneralInfoData,
  AddGeneralInfo,
  UpdateGeneralInfo,
} from "../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import UploadImageFile from "../Reusable/Upload/ImageUpload/UploadImageFile";
import AddNewButton from "../Reusable/Buttons/AddNewButton";
import ListView from "./Miscellaneous/ListView";
import BraftEditor from "braft-editor";
import ReactHtmlParser from "react-html-parser";
// ---------------------------- module imports ends ----------------------------------//

// ---------------------------- interface starts ----------------------------------//

interface InputFormI {
  id?: any;
  logo?: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
  school_reg_number: string;
  result_type: string;
  about_us: any;
}

interface resultTypeChoicesI {
  title: string;
  value: string;
}

// ---------------------------- interface ends ----------------------------------//

const GeneralInfo = (props: any) => {
  const classes = useStyles();

  // ---- States Declaration ------ //
  const [resultType, setResultType] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreating, SetIsCreating] = useState(false);
  const [isUpdating, SetIsUpdating] = useState(false);
  const [files, setFiles] = useState<any | null>([]);
  const [image, setImage] = useState<any | null>([]);
  const [isFile, setIsFile] = useState<boolean>(false);
  const [settingID, setSettingID] = useState<any>();
  const [studentImageOnChange, setStudentImageOnChange] =
    useState<boolean>(false);
  const [about, setAbout] = useState<any>(null);

  const [formValues, setFormValues] = useState<InputFormI>({
    school_name: "",
    address: "",
    phone_number: "",
    email: "",
    slogan: "",
    school_reg_number: "",
    result_type: "",
    about_us: "",
  });
  const [values, setValues] = useState<any[]>([]);
  const [onEditMode, setOnEditMode] = useState<boolean>(false);

  const dispatch = useDispatch();

  const generalInfoLoading = useSelector(
    (state: RootStore) => state.general_info.loading
  );

  const generalInfoData = useSelector((state: RootStore) => state.general_info);
  const logoValue = useSelector((state: RootStore) => state.general_info?.logo);

  const generalInfoId = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    if (authData.general_info != null) {
      console.log({ authData });

      dispatch(GetGeneralInfoData(authData.general_info.id));
    }
  }, []);

  useEffect(() => {
    setLoading(generalInfoLoading);
  }, [generalInfoLoading]);

  useEffect(() => {
    const data = generalInfoData?.general_info;
    console.log({ data });
    if (data) {
      setSettingID(generalInfoId?.id);
      handleFormInitialize(data);
      setValues([
        { name: "School Name", value: data?.school_name },
        { name: "School Registration Number", value: data?.school_reg_number },
        { name: "Full Address", value: data?.address },
        { name: "Phone Number", value: data?.phone_number },
        { name: "Email", value: data?.email },
        { name: "Result Type", value: data?.result_type },
        { name: "Slogan", value: data?.slogan },
        {
          name: "About Us",
          value: ReactHtmlParser(data?.about_us) || (
            <em>No Description Available</em>
          ),
        },
      ]);
      const htmlContent = data?.about_us;
      // setValues([
      //   { name: 'School Name', value: data?.school_name },
      //   { name: 'School Registration Number', value: data?.school_reg_number },
      //   { name: 'Full Address', value: data?.address },
      //   { name: 'Phone Number', value: data?.phone_number },
      //   { name: 'Email', value: data?.email },
      //   { name: 'Result Type', value: data?.result_type },
      //   { name: 'Slogan', value: data?.slogan },
      //   {
      //     name: 'About Us',
      //     value: ReactHtmlParser(data?.about_us) || (
      //       <em>No Description Available</em>
      //     ),
      //   },
      // ]);
      // const htmlContent = data?.about_us;
      setAbout(BraftEditor.createEditorState(htmlContent));
      // setResultType(resultTypeChoices[0]);
      setResultType(
        resultTypeChoices.find(
          (element) =>
            element.value === generalInfoData?.general_info?.result_type
        )
      );
      SetIsUpdating(true);
      SetIsCreating(false);
    } else {
      SetIsCreating(true);
      SetIsUpdating(false);
    }
  }, [generalInfoData]);

  // checking file state data is File Object or not.
  useEffect(() => {
    const isFile = (input: any) => "File" in window && input instanceof File;
    setIsFile(isFile(image && Image[0]));
  }, [files]);

  //Initializing form data
  const handleFormInitialize = (data: any) => {
    setImage(data?.logo);
    setFormValues({
      ...formValues,
      school_name: data?.school_name,
      address: data?.address,
      phone_number: data?.phone_number,
      email: data?.email,
      slogan: data?.slogan,
      school_reg_number: data?.school_reg_number,
      // result_type: data.result_type,
    });
  };

  //For each input field value change
  const handleValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const { register, handleSubmit, errors } = useForm<InputFormI>({
    mode: "onChange",
  });

  const onSubmit = (data: InputFormI) => {
    console.log({ data });
    const htmlContent = about.toHTML();
    const formData = new FormData();
    typeof image != "string" && formData.append("logo", image);
    !image && formData.append("logo", "");

    const form_data: InputFormI = {
      school_name: data.school_name,
      school_reg_number: data.school_reg_number,
      address: data.address,
      phone_number: data.phone_number,
      email: data.email,
      slogan: data.slogan,
      result_type: resultType.value,
      about_us: htmlContent,
    };

    Object.keys(form_data).map((key) => formData.append(key, form_data[key]));

    //for create case
    if (isCreating) {
      dispatch(AddGeneralInfo(formData));
    }

    //for update case
    if (isUpdating) {
      if (generalInfoData?.general_info) {
        dispatch(UpdateGeneralInfo(settingID, formData));
      }
    }
    setOnEditMode(false);
  };

  const resultTypeChoices: resultTypeChoicesI[] = [
    { title: "Marksheet", value: "M" },
    { title: "Gradesheet", value: "G" },
    { title: "Marksheet and Gradesheet", value: "MG" },
  ];

  return (
    <>
      {onEditMode && (
        <Paper className={classes.pageContent}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} className={classes.formTitle}>
                <span>General Info</span>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.formWrapper}>
                <InputLabel>Upload Photo</InputLabel>
                <UploadImageFile image={image} setImage={setImage} />
                <span className={classes.validationErrorInfo} />
              </Grid>
              <Grid item xs={7} className={classes.formWrapper}>
                <InputLabel>
                  School Name
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="school_name"
                  placeholder="School Name"
                  autoComplete="off"
                  inputRef={register({ required: true })}
                  onChange={handleValueChange}
                  value={formValues.school_name}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.school_name?.type === "required" &&
                    "please input school name"}
                </span>
              </Grid>
              <Grid item xs={5} className={classes.formWrapper}>
                <InputLabel>School Reg Number </InputLabel>
                <TextField
                  variant="outlined"
                  name="school_reg_number"
                  placeholder="School Registration Number"
                  inputRef={register}
                  onChange={handleValueChange}
                  value={formValues.school_reg_number}
                />
                <span className={classes.validationErrorInfo} />
              </Grid>
              <Grid item xs={12} className={classes.formWrapper}>
                <InputLabel>Full Address </InputLabel>
                <TextField
                  variant="outlined"
                  name="address"
                  placeholder="Full Address"
                  inputRef={register}
                  onChange={handleValueChange}
                  value={formValues.address}
                />
                <span className={classes.validationErrorInfo} />
              </Grid>
              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>Phone </InputLabel>
                <TextField
                  variant="outlined"
                  name="phone_number"
                  placeholder="Phone Number"
                  inputRef={register}
                  onChange={handleValueChange}
                  value={formValues.phone_number}
                />
                <span className={classes.validationErrorInfo} />
              </Grid>
              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>Email </InputLabel>
                <TextField
                  variant="outlined"
                  name="email"
                  placeholder="Email Address"
                  inputRef={register}
                  onChange={handleValueChange}
                  value={formValues.email}
                />
                <span className={classes.validationErrorInfo} />
              </Grid>
              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>
                  Result Type
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: resultTypeChoicesI | null
                  ) => setResultType(value != null ? value : null)}
                  options={resultTypeChoices}
                  getOptionLabel={(option) => option.title}
                  value={resultType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="class"
                      placeholder="Class"
                      variant="outlined"
                    />
                  )}
                />
                <span className={classes.validationErrorInfo} />
              </Grid>
              <Grid item xs={6} className={classes.formWrapper}>
                <InputLabel>Slogan</InputLabel>
                <TextField
                  variant="outlined"
                  multiline
                  rows={4}
                  name="slogan"
                  placeholder="Slogan"
                  inputRef={register}
                  onChange={handleValueChange}
                  value={formValues.slogan}
                />
                <span className={classes.validationErrorInfo} />
              </Grid>
              <Grid
                style={{ margin: "9px", gap: "1rem" }}
                container
                justifyContent="flex-end"
              >
                <AddNewButton
                  title="Cancel"
                  type="button"
                  color="primary"
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={() => setOnEditMode(false)}
                >
                  Cancel
                </AddNewButton>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
            {/* {loading && <LinearProgress className={classes.formProgress} />} */}
          </form>
        </Paper>
      )}
      {!onEditMode && (
        <ListView
          title="General Info"
          setOnEditMode={setOnEditMode}
          data={values}
        />
      )}
    </>
  );
};

export default GeneralInfo;
